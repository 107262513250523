import { useState, useEffect } from 'react';
import { LinkButton } from '../../components/linkButton';
import { useNavigate } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';
import { LPDeliveryState, LPFormState, LPAddressState, LPtotalCountState, LPCartState } from '../../store/atoms';
import { importAll } from '../../utils/image';
import { Wrapper } from './scss';
import { Calender } from '../../components/calender2';
import { PullDown } from '../../components/pullDown';
import dayjs from 'dayjs';
import { FormHeader } from '../../components/fiveStepBar';

export const LPDeliveryInfo = () => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const navigate = useNavigate();
  const totalCount = useRecoilValue(LPtotalCountState);
  const inputFormWidth = [340, 512, 720];
  const inputFormHalfWidth = [160, 246, 350];
  const inputFormHeight = [32, 38, 44];
  const linkButtonHeight = [48, 52, 60];
  const [delivery, setDelivery] = useRecoilState(LPDeliveryState);
  const [recoilForm, setRecoilForm] = useRecoilState(LPFormState);
  const [address, setAddress] = useRecoilState(LPAddressState);
  const [addressList, setAddressList] = useState([]);
  const [isValid, setIsValid] = useState(recoilForm.isValid);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(delivery?.date || null);
  const [deliveryTime, setDeliveryTime] = useState(delivery?.time || '時間指定なし');
  const [showCalender, setShowCalender] = useState(false);
  const [cart, setCart] = useRecoilState(LPCartState);
  const postponeDate = cart.items['uid39'].count > 0 || cart.items['uid40'].count > 0 || cart.items['uid16'].count > 0;
  const [form, setForm] = useState({
    deliveryDate: delivery?.date ? 'valid' : 'not filled',
    deliveryTime: delivery?.time ? 'valid' : 'not filled',
  });
  const setFormByProp = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const setDeliveryByProp = (key, value) => {
    setDelivery((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };

  // MEMO : フォーム全体が有効かどうかを判定する
  useEffect(() => {
    if (isValid) return;
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (!isValid) setButtonIsLoading(false);
  }, [isValid]);

  useEffect(() => {
    console.log('Delivery date:', deliveryDate);
    if (deliveryDate) {
      setDeliveryByProp('date', deliveryDate);
      setFormByProp('deliveryDate', 'valid');
    } else {
      setDeliveryByProp('date', '希望日指定なし');
      setFormByProp('deliveryDate', 'not filled');
      setIsValid(false);
    }
  }, [deliveryDate]);

  useEffect(() => {
    if (deliveryTime) {
      setDeliveryByProp('time', deliveryTime);
      setFormByProp('deliveryTime', 'valid');
    } else {
      setDeliveryByProp('time', '時間指定なし');
      setFormByProp('deliveryTime', 'not filled');
    }
  }, [deliveryTime]);

  useEffect(() => {
    console.log(address);
    const addressList = address.list.map((item, key) => {
      return (
        <div
          key={key}
          className="addressList"
          onClick={() => {
            setAddress({
              ...address,
              selected: key,
            });
          }}
        >
          <div>
            <div>
              {item.lastName} {item.firstName}
            </div>
            <div>〒{item.zipCode}</div>
            <div>
              {item.prefecture}
              {item.city}
              {item.suburb}
              {item.street}
            </div>
            <div>{item.building}</div>
            <div>{item.corporation}</div>
            <div>{item.phoneNum}</div>
          </div>
          <div className="rightWrap">
            {address.selected === key && key > 0 && (
              <div
                className="editButton"
                onClick={() => {
                  navigate(`/delivery/address-info?id=${key}`);
                }}
              >
                編集
              </div>
            )}
            <div className="checkBox">{address.selected === key ? '◉' : '○'}</div>
          </div>
        </div>
      );
    });
    setAddressList(addressList);
  }, [address]);

  useEffect(() => {
    // MEMO : 直接アクセスされた場合のリダイレクト
    if (totalCount === 0) {
      navigate('/');
    }
  }, []);

  return (
    <Wrapper
      inputFormWidth={inputFormWidth}
      inputFormHalfWidth={inputFormHalfWidth}
      inputFormHeight={inputFormHeight}
      linkButtonHeight={linkButtonHeight}
    >
      <FormHeader step={2} title="配送" />
      <div className="addressTitle">配送先</div>
      {addressList}
      <div
        className="addAddressButtonWrap"
        onClick={() => {
          setRecoilForm({
            ...recoilForm,
            delivery_deliveryFormIsValid: isValid,
            content: {
              ...recoilForm.content,
              ...form,
            },
          });
          navigate(`/delivery/address-info?id=${address.list.length}`);
        }}
      >
        <div className="addAddressButton">別の配送先を追加する</div>
        <img src={image['arrow_down.svg']} />
      </div>

      <PullDown
        className="marginBottom"
        title="配送希望日"
        // description={deliveryDate && dayjs(deliveryDate).format('YYYY年MM月DD日')}
        // description={deliveryDate ? dayjs(deliveryDate).format('YYYY年MM月DD日') : '希望日指定なし'}
        description={
          !deliveryDate // If deliveryDate is not set, show '希望日指定なし'
            ? '希望日指定なし'
            : typeof deliveryDate === 'string' // If deliveryDate is a string, show it as is
            ? deliveryDate
            : dayjs(deliveryDate).format('YYYY年MM月DD日') // Otherwise, format it as a date
        }
        onClick={() => {
          setShowCalender(!showCalender);
        }}
      />
      {showCalender && (
        <Calender
          className="marginBottom"
          width={[320, 420, 480]}
          startDate={postponeDate ? dayjs().add(14, 'day') : dayjs().add(2, 'day')}
          endDate={postponeDate ? dayjs().add(28, 'day') : dayjs().add(16, 'day')}
          // selectedDate={dayjs(deliveryDate)}
          selectedDate={deliveryDate ? dayjs(deliveryDate) : null}
          setSelectedDate={(date) => {
            setDeliveryDate(date && date.format('YYYY-MM-DD'));
          }}
        />
      )}
      <PullDown
        className=""
        title="配送希望時間"
        description={deliveryTime || '時間指定なし'}
        setItem={setDeliveryTime}
      >
        <p>時間指定なし</p>
        <p>8:00~12:00</p>
        <p>14:00~16:00</p>
        <p>16:00~18:00</p>
        <p>18:00~20:00</p>
        <p>19:00~21:00</p>
      </PullDown>
      <LinkButton
        className="linkButton"
        width={inputFormWidth}
        height={linkButtonHeight}
        text="決済情報の入力へ進む"
        color="black"
        // active={isValid}
        active={true}
        loading={buttonIsLoading}
        onClick={() => {
          setButtonIsLoading(true);
          setRecoilForm({
            ...recoilForm,
            delivery_deliveryFormIsValid: isValid,
            content: {
              ...recoilForm.content,
              ...form,
            },
          });
          navigate('/delivery/payment');
        }}
      />
      <LinkButton
        width={inputFormWidth}
        height={linkButtonHeight}
        text="お客様情報に戻る"
        color="white"
        arrow="left"
        active={true}
        onClick={() => {
          setButtonIsLoading(true);
          setRecoilForm({
            ...recoilForm,
            delivery_deliveryFormIsValid: isValid,
            content: {
              ...recoilForm.content,
              ...form,
            },
          });
          navigate('/delivery/customer-info');
        }}
      />
    </Wrapper>
  );
};
